@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-notifications/styles/material.css';

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
}
